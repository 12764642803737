const itemData = [
  {
    id: 1,
    img:
      'https://i.ibb.co/cvFkBJG/115729328-1556482197892438-4115520208736122855-n.jpg',
    title: 'Sea food pailla',
  },
  {
    id: 2,
    img:
      'https://i.ibb.co/5YyTcst/116265302-365594127757332-8299778177737545174-n.jpg',
    title: 'Qaraeen',
  },
  {
    id: 3,
    img:
      ' https://i.ibb.co/HT1DTZb/116007721-766188630858096-3318127095960974111-n.jpg',
    title: 'Tagine chicken olives lemon',
  },
  {
    id: 4,
    img:
      'https://i.ibb.co/312jSZP/112987903-163133982000513-2812928068170660937-n.jpg',
    title: 'Tagine chicken artichoke',
  },
  {
    id: 5,
    img:
      ' https://i.ibb.co/QrtHFrt/109548502-309280920268487-7658155045392981510-n.jpg',
    title: 'Tagine chicken vegetables',
  },
  {
    id: 6,
    img:
      'https://i.ibb.co/b5tKX3n/116075081-2797831240531008-4865369988602662030-n.jpg',
    title: 'Fried sardines',
  },
  {
    id: 7,
    img:
      'https://i.ibb.co/MpL0DWH/109201901-878075402682893-4503446290339636427-n.jpg',
    title: 'Asifa chicken tagine',
  },
  {
    id: 8,
    img:
      'https://i.ibb.co/HGnmWHH/116007719-3516102881756612-4622539547773921055-n.jpg',
    title: 'Tagine chicken oninon almonds',
  },
  {
    id: 9,
    img:
      ' https://i.ibb.co/r4j287J/110015496-282333762997645-8515466196686240474-n.jpg',
    title: 'Sardines kawari',
  },
  {
    id: 10,
    img:
      'https://i.ibb.co/5LKxBGP/110258864-193745368765123-6994445741089015906-n.jpg',
    title: 'Spicy fish tagine',
  },
  {
    id: 11,
    img:
      ' https://i.ibb.co/r4TVxvS/109115331-685622291993793-8048626973134422808-n.jpg',
    title: 'Seafood mix',
  },
  {
    id: 12,
    img:
      ' https://i.ibb.co/ph11Ls2/109208931-117637666697325-7585939617927707021-n.jpg',
    title: 'Rafissa',
  },
]
export default itemData
