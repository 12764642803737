import PropTypes from 'prop-types'
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Carousel } from 'react-bootstrap'
import itemData from './Data'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
        onKeyDown={() => {
          this.props.onCloseArticle()
        }}
        aria-hidden="true"
        role="button"
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="food"
          className={`${this.props.article === 'food' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">food</h2>

          <p>
            Our best selling dishes are Harira, Chicken Tagine Couscous,
            Boukadiosse Sandwich and Tuna Sandwich, although we have a variety
            of dishes and meals to choose from. We are sure you will find your
            perfect meal at our restaurant .
            <span id="order">
              For more and to order please check our
              <span>
                {' '}
                <a
                  style={{ color: 'yellow', marginLeft: 10 }}
                  href="https://order.chatfood.io/f/al-wedayah-al-maghribi"
                >
                  <i className="fas fa-link"></i> Menu.
                </a>
              </span>
              <span>
                <i className="icon fas fa-utensils"></i>
              </span>
            </span>
          </p>
          <Carousel>
            {itemData.map((item) => (
              <Carousel.Item key={item.id}>
                <span className="image main">
                  <img src={item.img} alt={item.title} />
                </span>
              </Carousel.Item>
            ))}
          </Carousel>

          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <StaticImage
              src="../images/pic01.jpg"
              alt="restaurant"
              formate={['auto', 'webp', 'avif']}
            />
          </span>
          <p>
            AL WEDAYAH AL MAGHRIBI is a restaurant and cafe in the heart of
            Dubai, We serve a selection of Moroccan dishes that delivers across
            Abu Hail, Al Garhoud and Al Baraha.
          </p>

          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>
                Don't fill this out: <input name="bot-field" />
              </label>
            </p>

            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://www.facebook.com/AlWedayahdxb/"
                target="_blank"
                rel="noopener noreferrer"
                className="icon fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/wedayah_al_maghribi/"
                target="_blank"
                rel="noopener noreferrer"
                className="icon fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a href="#/" className="icon fa-phone">
                <span className="label">phone</span>
              </a>
            </li>
            <li>
              <h3>+971 4 266 6628</h3>
            </li>
            <li>
              <h4>
                AL WEDAYAH AL MAGHRIBI, <br /> Abu Hail Dubai, 00001 United Arab
                Emirates.
              </h4>
            </li>
          </ul>

          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
