import React from 'react'
import PropTypes from 'prop-types'

const Footer = props => (
  <footer id="footer" style={props.timeout ? { display: 'none' } : {}}>
    <p className="copyright">
      &copy; Al Wedayah Al Maghribi Developed by:{' '}
      <a
        href="https://www.linkedin.com/in/sami-ilahi/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Sami Ilahi
      </a>
      .
    </p>
  </footer>
)

Footer.propTypes = {
  timeout: PropTypes.bool,
}

export default Footer
